exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-funkcje-finansowy-index-js": () => import("./../../../src/pages/funkcje/finansowy/index.js" /* webpackChunkName: "component---src-pages-funkcje-finansowy-index-js" */),
  "component---src-pages-funkcje-index-js": () => import("./../../../src/pages/funkcje/index.js" /* webpackChunkName: "component---src-pages-funkcje-index-js" */),
  "component---src-pages-funkcje-planowanie-i-prognoza-index-js": () => import("./../../../src/pages/funkcje/planowanie-i-prognoza/index.js" /* webpackChunkName: "component---src-pages-funkcje-planowanie-i-prognoza-index-js" */),
  "component---src-pages-funkcje-widok-z-lotu-ptaka-index-js": () => import("./../../../src/pages/funkcje/widok-z-lotu-ptaka/index.js" /* webpackChunkName: "component---src-pages-funkcje-widok-z-lotu-ptaka-index-js" */),
  "component---src-pages-funkcje-wspolpraca-index-js": () => import("./../../../src/pages/funkcje/wspolpraca/index.js" /* webpackChunkName: "component---src-pages-funkcje-wspolpraca-index-js" */),
  "component---src-pages-funkcje-zarzadzanie-zadaniami-index-js": () => import("./../../../src/pages/funkcje/zarzadzanie-zadaniami/index.js" /* webpackChunkName: "component---src-pages-funkcje-zarzadzanie-zadaniami-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-missing-translation-index-js": () => import("./../../../src/pages/missing-translation/index.js" /* webpackChunkName: "component---src-pages-missing-translation-index-js" */),
  "component---src-pages-plany-cenowe-index-js": () => import("./../../../src/pages/plany-cenowe/index.js" /* webpackChunkName: "component---src-pages-plany-cenowe-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-publiczne-api-index-js": () => import("./../../../src/pages/publiczne-api/index.js" /* webpackChunkName: "component---src-pages-publiczne-api-index-js" */),
  "component---src-pages-warunki-korzystania-z-uslugi-index-js": () => import("./../../../src/pages/warunki-korzystania-z-uslugi/index.js" /* webpackChunkName: "component---src-pages-warunki-korzystania-z-uslugi-index-js" */),
  "component---src-pages-zespot-index-js": () => import("./../../../src/pages/zespot/index.js" /* webpackChunkName: "component---src-pages-zespot-index-js" */),
  "component---src-templates-blog-blog-index-js": () => import("./../../../src/templates/blog/blog-index.js" /* webpackChunkName: "component---src-templates-blog-blog-index-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-features-feature-details-js": () => import("./../../../src/templates/features/feature-details.js" /* webpackChunkName: "component---src-templates-features-feature-details-js" */)
}

